import { template as template_be94b4df70194b93bc0ab06fd0bcd84d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_be94b4df70194b93bc0ab06fd0bcd84d(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
