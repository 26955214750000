import { template as template_e923c10911694fe4b2cdab47a6f91c42 } from "@ember/template-compiler";
const FKLabel = template_e923c10911694fe4b2cdab47a6f91c42(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
